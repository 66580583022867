import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router'
import {
  LAYOUT_BARE,
  LAYOUT_MAIN,
  REDIRECT_TO,
  ROUTE_LOGIN,
  ROUTE_LOGIN_STUDENT_LOGIN,
  ROUTE_ROOT,
  USER_AGENT_CHROME_OS,
} from '../constants'
import { RequireAuthContext, useAuthContext } from '../context'
import useCheckBrowserUserAgent from '../hooks/utilities/useCheckBrowserUserAgent'
import BareLayout from '../layouts/BareLayout'
import MainLayout from '../layouts/MainLayout'
import { TYPE_ROUTE_HANDLER } from '../types'
import { clearSessionToken, isTokenExpired } from '../utils/auth'

const RequireAuth = ({ redirectPath = ROUTE_ROOT, module = null, roles = null, layout = LAYOUT_MAIN }) => {
  const { isAuthenticated, setIsSessionExpired } = useAuthContext()
  const location = useLocation()
  const ProtectedLayout = useMemo(
    () =>
      ({
        [LAYOUT_MAIN]: MainLayout,
        [LAYOUT_BARE]: BareLayout,
      }[layout]),
    [layout]
  )
  const isUserOnChromeOS = useCheckBrowserUserAgent(USER_AGENT_CHROME_OS)

  useEffect(() => {
    if (isTokenExpired() && !isAuthenticated) {
      setIsSessionExpired(true)
      clearSessionToken()
    }

    return () => {
      if (isAuthenticated && window.sessionStorage.getItem(REDIRECT_TO)) {
        window.sessionStorage.removeItem(REDIRECT_TO)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (!isTokenExpired() && isAuthenticated) {
    return (
      <RequireAuthContext.Provider value={{ redirectPath, module, roles }}>
        <ProtectedLayout />
      </RequireAuthContext.Provider>
    )
  }

  const redirectTo = location.pathname + location.search
  window.sessionStorage.setItem(REDIRECT_TO, redirectTo)
  const loginRoute = isUserOnChromeOS ? ROUTE_LOGIN_STUDENT_LOGIN : ROUTE_LOGIN

  return <Navigate to={loginRoute} replace />
}

RequireAuth.propTypes = TYPE_ROUTE_HANDLER

RequireAuthContext.Provider.propTypes = {
  value: PropTypes.shape(TYPE_ROUTE_HANDLER),
}

export default RequireAuth
